<template>
  <homeHeader />
  <div class="center-container">
    <slot />
  </div>
  <defaultFooter />
</template>

<style lang="scss" scoped>
.center-container {
  background-color: white;
  min-height: 100vh;
}
</style>
