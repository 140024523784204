<script setup lang="ts">

</script>

<template>
  <div class="home-top-header">
    <div id="init_header" />
  </div>
</template>

<style lang="scss" scoped>
.home-top-header {
    height: 560px;
    background: url('//file.hopegoocdn.com/hopegoo/web-pc/images/Home_Bg_top3.png') no-repeat center center #00C868;
    background-size: auto 100%;
}
</style>
